// inside app/frontend/js/bootstrap_js_files.js

import 'bootstrap/js/src/alert'  
import 'bootstrap/js/src/button'  
import 'bootstrap/js/src/carousel'  
import 'bootstrap/js/src/collapse'  
import 'bootstrap/js/src/dropdown'  
import 'bootstrap/js/src/modal'  
import 'bootstrap/js/src/popover'  
import 'bootstrap/js/src/scrollspy'  
import 'bootstrap/js/src/tab'  
import 'bootstrap/js/src/toast'  
import 'bootstrap/js/src/tooltip'  


var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
});
